import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { UserStore } from '../../../state/user/UserStore';
import {
  CLIENT_TYPE,
  hasAccessToOpenBanking,
  isOnlyClientAndOptionalBackOffice,
  isOperator,
  PROFILES,
} from '../../../utils/rights';
import { FolderStore } from '../../../state/folder/FolderStore';
import BmwAlert from '../../BmwAlert';
import { Button, ButtonGroup, Collapse } from 'react-bootstrap';
import BmwSelect from '../../form/BmwSelect';
import {
  CATEGORY,
  FILE_SIZE,
  MAX_IMAGES_PER_DOCUMENT,
  REFUSAL_TYPE,
  STATUS,
} from '../../../utils/constants';
import { TypeActor, TypeDocument } from '../../../types/globalTypes';
import BmwLoader from '../../BmwLoader';
import { DocumentTypeStore } from '../../../state/documentType/DocumentTypeStore';
import { DocumentTypeItemSubtypeItemResponse } from '../../../types/model/documentTypeItemSubtypeItemResponse';
import FileItem from './FileItem';
import { DocumentTypeItemResponse } from '../../../types/model/documentTypeItemResponse';
import { ActorStore } from '../../../state/actor/ActorStore';
import { TypeFolderStore, TypeUserStore } from '../../../types/storeTypes';
import BmwCheckbox from '../../form/BmwCheckbox';
import { restPatchHandler } from '../../../rest/RestClient';
import { DocumentIgnoreUpdateRequest } from '../../../types/model/documentIgnoreUpdateRequest';
import { DocumentResponse } from '../../../types/model/documentResponse';
import {
  requestGetDocumentActor,
  requestGetDocumentFolder,
} from '../../../state/documents/DocumentsEffects';
import {
  getActorName,
  getTotalFileSize,
  handleParsedResponse,
} from '../../../utils/utils';
import BmwButton from '../../form/BmwButton';
import { requestGetActorCompletion } from '../../../state/actor/ActorEffects';
import { RefusalItemResponse } from '../../../types/model/refusalItemResponse';
import { RefusalStore } from '../../../state/refusal/RefusalStore';
import { requestUploadInfo } from '../../../state/uploadDocuments/UploadDocumentsEffects';
import { requestGetFolderCompletion } from '../../../state/folder/FolderEffects';
import { DocumentTargetEnum } from '../../../types/model/documentTargetEnum';
import { DOCUMENTS } from '../../../rest/apiPath';
import ReactHtmlParser from 'react-html-parser';
import { DocumentItemFileResponse } from '../../../types/model/documentItemFileResponse';
import { DocumentMandatoryUpdateRequest } from '../../../types/model/DocumentMandatoryUpdateRequest';
import OpenBankingIframeButton from './OpenBankingIframeButton';
import configuration from '../../../utils/Config';

type Props = {
  id: string;
  valid?: boolean;
  error?: boolean;
  warning?: boolean;
  ignored?: boolean;
  file: TypeDocument;
  category: string;
  disabled?: boolean;
  mandatory?: boolean;
};

const FileContainer = (props: Props) => {
  const folderStore = useStore<TypeFolderStore>(FolderStore);
  const documentTypeStore =
    useStore<DocumentTypeItemResponse[]>(DocumentTypeStore);
  const actorStore = useStore<TypeActor[]>(ActorStore);
  const userStore = useStore<TypeUserStore>(UserStore);
  const refusalStore = useStore<RefusalItemResponse[]>(RefusalStore);
  const [open, setOpen] = useState(false as boolean);
  const [selected, setSelected] = useState(false as boolean);
  const [subtypeId, setSubtypeId] = useState<string>();
  const [owner, setOwner] = useState<string>();
  const [hasVerso, setHasVerso] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadExtraFile, setLoadExtraFile] = useState<boolean>(false);
  const [showOpenBankingModal, setShowOpenBankingModal] =
    useState<boolean>(false);
  const openBankingRights = hasAccessToOpenBanking(userStore.profiles);
  const [files, setFiles] = useState<DocumentItemFileResponse[]>([]);
  const {
    id,
    valid,
    error,
    warning,
    file,
    category,
    disabled = false,
    mandatory,
  } = props;
  const { t } = useTranslation();

  const prevHasVersoRef = useRef<boolean>();
  useEffect(() => {
    prevHasVersoRef.current = hasVerso;
  });

  useEffect(() => {
    setFiles(file.files);
  }, [file]);

  useEffect(() => {
    setHasVerso(files.length > 1);
  }, [files]);

  useEffect(() => {
    setLoadExtraFile(files[0] && !files[0]?.name.includes('.pdf'));
  }, [files]);

  // Initialize document subtype on component load
  useEffect(() => {
    if (!!file.subtype || !!file.type) {
      if (!!file.subtype) {
        setSubtypeId(file.subtype.id);
      } else {
        const type = documentTypeStore.find((t) => t.id === file.type.id);
        if (type?.subtypes.length === 1) {
          setSubtypeId(type.subtypes[0].id);
        }
      }
    }
  }, [file.subtype, file.type, documentTypeStore]);

  useEffect(() => {
    const type = documentTypeStore.find((value) => value.id === file.type.id);
    const subtype = type?.subtypes.find(
      (subtype: DocumentTypeItemSubtypeItemResponse) =>
        subtype.id === subtypeId,
    );
    if (subtype?.possibleOtherOwner && !file.otherOwnerId) {
      const actor = actorStore.find(
        (actor: TypeActor) => actor.id === file.actorId,
      );
      setOwner(actor?.id);
    }
  }, [documentTypeStore, file, subtypeId, actorStore]);

  useEffect(() => {
    if (
      file.ignored ||
      ((file.rejected || file.updated) &&
        folderStore.status !== STATUS.STUDY_SUPPLY_DOCUMENT)
    ) {
      setSelected(true);
    } else {
      setSelected(false);
    }
    if (file.otherOwnerId) {
      setOwner(file.otherOwnerId);
    }
  }, [file, folderStore.status]);

  const getClassnames = (
    disabled: boolean,
    valid?: boolean,
    error?: boolean,
    warning?: boolean,
  ) =>
    `${disabled ? 'disabled' : ''} ${valid && !disabled ? 'valid' : ''} ${
      error && !disabled ? 'error' : ''
    } ${warning && !disabled ? 'warning' : ''}`;

  const getFileTitle = (
    subtype: DocumentTypeItemSubtypeItemResponse,
    fileIndex: number,
    files: DocumentItemFileResponse[],
  ): string => {
    const serverFile = files.find((f) => f.fileIndex === fileIndex);
    return serverFile
      ? serverFile.name
      : t(
          `folder.files.${
            fileIndex === 0
              ? subtype?.doubleSided
                ? 'loadRecto'
                : 'loadFile'
              : subtype?.doubleSided
              ? 'loadVerso'
              : 'loadExtraFile'
          }`,
        );
  };

  const hasMotif = !!file.refusal;
  const showMotif =
    hasMotif && !isOperator(userStore.profiles) && file.rejected;

  const disable = file.ignored || disabled || loading;
  const type = documentTypeStore.find((value) => value.id === file.type.id);
  const subtype = type?.subtypes.find(
    (subtype: DocumentTypeItemSubtypeItemResponse) => subtype.id === subtypeId,
  );
  if (!type) {
    return <BmwLoader />;
  }
  const options = [
    { value: '', label: `${t('folder.files.choose')} ${type.labelName}` },
  ];
  type.subtypes.forEach((subType: DocumentTypeItemSubtypeItemResponse) =>
    options.push({ value: subType.id, label: subType.labelName || '' }),
  );

  const ownerOptions = [];
  if (userStore.profiles.includes(PROFILES.CLIENT)) {
    const actor = actorStore.find(
      (actor: TypeActor) => actor.id === userStore.actorId,
    );
    if (actor) {
      ownerOptions.push({
        value: actor.id,
        label: t('folder.files.myName', { file: type.labelName }),
      });
    }
  }
  actorStore
    .filter(
      (actor: TypeActor) =>
        (!userStore.profiles.includes(PROFILES.CLIENT) ||
          actor.id !== userStore.actorId) &&
        actor.type !== CLIENT_TYPE.TERTIARY,
    )
    .forEach((actor: TypeActor) => {
      ownerOptions.push({
        value: actor.id,
        label: t('folder.files.otherName', {
          file: type.labelName,
          name: getActorName(actor),
        }),
      });
    });

  const updateDocument = () => {
    if (category === CATEGORY.ACTOR && file.actorId) {
      const getDocumentPromise = requestGetDocumentActor({
        id: file.actorId,
      });
      const getCompletionPromise = requestGetActorCompletion({
        id: file.actorId,
      });
      Promise.all([getDocumentPromise, getCompletionPromise]).finally(() =>
        setLoading(false),
      );
    } else if (category === CATEGORY.FOLDER && file.folderId) {
      const getDocumentPromise = requestGetDocumentFolder({
        id: file.folderId,
      });
      const getCompletionPromise = requestGetFolderCompletion({
        id: file.folderId,
      });
      Promise.all([getDocumentPromise, getCompletionPromise]).finally(() =>
        setLoading(false),
      );
    }
  };

  const refuse = (id?: string) => {
    restPatchHandler(DOCUMENTS.UPDATE_REFUSAL)({
      id: file.id,
      dto: {
        refusalId: !id ? null : id || '',
      },
    }).then((result) =>
      handleParsedResponse(result)(
        () => updateDocument(),
        () => updateDocument(),
      ),
    );
  };

  const refusalType =
    folderStore.status === STATUS.STUDY_SEND_CONTROL ||
    folderStore.status === STATUS.STUDY_RETAKE_SUPPLY
      ? folderStore.onePDF
        ? REFUSAL_TYPE.STUDY_ONE_PDF
        : REFUSAL_TYPE.STUDY_DOCUMENT
      : REFUSAL_TYPE.PAYMENT_DOCUMENT;

  const getTargetPrefix = () =>
    file.target === DocumentTargetEnum.SIGNATORY ||
    file.target === DocumentTargetEnum.LODGER
      ? `${t(`folder.actor.${file.target.toLowerCase()}`)} - `
      : '';

  const getTargetSuffix = () =>
    file.target === DocumentTargetEnum.SIGNATORY ||
    file.target === DocumentTargetEnum.LODGER
      ? ` du ${t(`folder.actor.${file.target.toLowerCase()}`).toLowerCase()}`
      : '';

  const onSubtypeChange = (e: { target: HTMLSelectElement }) => {
    const value = e.target.value;
    const subtypeItem = type?.subtypes.find(
      (item: DocumentTypeItemSubtypeItemResponse) => item.id === value,
    );
    setSubtypeId(value);
    requestUploadInfo({
      id: file.id,
      subtypeId: value,
      ownerId: subtypeItem?.possibleOtherOwner
        ? owner || file.actorId
        : undefined,
      isClient: isOnlyClientAndOptionalBackOffice(userStore.profiles),
    });
  };

  const onAddFile = (file: DocumentItemFileResponse) => {
    let array = files;
    if (array.length > file.fileIndex) {
      array[file.fileIndex] = file;
    } else {
      array.push(file);
    }
    setFiles(array);
  };

  const onRemoveFile = (fileIndex: number) => {
    let array: DocumentItemFileResponse[] = files.filter((file) => {
      if (file.fileIndex === fileIndex) return null;
      if (file.fileIndex > fileIndex) {
        file.fileIndex--;
        return file;
      }
      return file;
    });
    setFiles(array);
  };

  const renderDoubleSidedFileList = () => {
    if (subtype)
      return (
        <>
          <FileItem
            disabled={disable}
            loading={loading}
            files={files}
            fileId={file.id}
            title={getFileTitle(subtype, 0, files)}
            isUploaded={!!files.find((f) => f.fileIndex === 0)}
            fileIndex={0}
            key={0}
            subtypeId={subtypeId}
            ownerId={owner}
            onAddFile={(fileData: DocumentItemFileResponse) => {
              onAddFile(fileData);
            }}
            onRemoveFile={(fileIndex: number) => {
              onRemoveFile(fileIndex);
            }}
            isDoubleSided
          />
          <BmwCheckbox
            disabled={files.length === 2 || loading}
            id={`${id}_verso`}
            label={t('folder.files.hasVerso')}
            className={hasVerso ? 'mb-3' : ''}
            onClick={(value) => setHasVerso(value)}
            checked={hasVerso}
          />
          {hasVerso && (
            <FileItem
              disabled={disable}
              loading={loading}
              files={files}
              fileId={file.id}
              title={getFileTitle(subtype, 1, files)}
              isUploaded={!!file.files.find((f) => f.fileIndex === 1)}
              subtypeId={subtypeId}
              ownerId={owner}
              fileIndex={1}
              key={1}
              onAddFile={(fileData: DocumentItemFileResponse) => {
                onAddFile(fileData);
              }}
              onRemoveFile={(fileIndex: number) => {
                onRemoveFile(fileIndex);
              }}
              isDoubleSided
            />
          )}
        </>
      );
  };

  const renderFileList = () => {
    if (subtype) {
      return (
        <>
          {[...Array(files.length > 0 ? files.length + 1 : 2)].map((f, i) => {
            let isExtra = i > 0 && i >= files.length;
            return (
              <FileItem
                loading={disable}
                files={files}
                fileId={file.id}
                title={getFileTitle(subtype, i, files)}
                isUploaded={!!files.find((fi) => fi.fileIndex === i)}
                subtypeId={subtype.id}
                ownerId={owner}
                fileIndex={i}
                key={i}
                excludePdf={i > 0 || files.length > 1}
                isExtraButton={isExtra}
                disabled={
                  disabled ||
                  (isExtra && !loadExtraFile) ||
                  i > MAX_IMAGES_PER_DOCUMENT - 1 ||
                  getTotalFileSize(files) >
                    FILE_SIZE.IMAGE * MAX_IMAGES_PER_DOCUMENT
                }
                onAddFile={(fileData: DocumentItemFileResponse) => {
                  onAddFile(fileData);
                }}
                onRemoveFile={(fileIndex: number) => {
                  onRemoveFile(fileIndex);
                }}
              />
            );
          })}
        </>
      );
    }
  };

  return (
    <>
      <div className="btn-toolbar">
        <button
          type="button"
          className={`btn btn-file btn-collapse mb-3 d-block ${getClassnames(
            file.ignored,
            valid,
            error,
            warning,
          )}`}
          onClick={() => {
            if (
              open ||
              ((file.rejected ||
                file.updated ||
                (file.uploaded &&
                  (folderStore.status === STATUS.STUDY_SUPPLY_DOCUMENT ||
                    type.id === '2007')) ||
                file.pending) &&
                !disable)
            ) {
              setOpen(!open);
            }
          }}
          disabled={disable}
          aria-expanded={open}
          aria-controls={id}
        >
          {`${getTargetPrefix()}${type.labelName}${getTargetSuffix()}`}
        </button>
        {showMotif && (
          <div className="d-flex ml-3 motif">
            <BmwAlert
              type={file.rejected ? 'danger' : 'warning'}
              label={file.refusal?.reason || ''}
            />
          </div>
        )}
        {isOperator(userStore.profiles) && !showMotif && (
          <>
            <ButtonGroup className="ml-3">
              <BmwButton
                label={t(
                  `actions.${
                    folderStore.status === STATUS.STUDY_SUPPLY_DOCUMENT ||
                    file.ignored ||
                    file.pending
                      ? 'ignore'
                      : 'refuse'
                  }`,
                )}
                disabled={disabled}
                loading={loading}
                type="tertiary"
                className={`${selected ? 'selected' : ''}`}
                onClick={() => {
                  if (
                    folderStore.status === STATUS.STUDY_SUPPLY_DOCUMENT ||
                    file.pending ||
                    file.ignored
                  ) {
                    setLoading(true);
                    restPatchHandler<
                      DocumentIgnoreUpdateRequest,
                      DocumentResponse
                    >(DOCUMENTS.UPDATE_IGNORE)({
                      id: file.id,
                      dto: { ignore: !selected },
                    }).then((result) =>
                      handleParsedResponse(result)(
                        () => updateDocument(),
                        () => updateDocument(),
                      ),
                    );
                  } else {
                    setLoading(true);
                    refuse(selected ? undefined : '0');
                    setSelected(!selected);
                  }
                  if (open) {
                    setOpen(!open);
                  }
                }}
              />
            </ButtonGroup>
            {selected &&
              !file.ignored &&
              folderStore.status !== STATUS.STUDY_SUPPLY_DOCUMENT && (
                <BmwSelect
                  disabled={disable}
                  onChange={(e) => refuse(e.target.value)}
                  className="ml-3 flex-auto"
                  options={[
                    { value: '0', label: 'Sélectionnez un motif' },
                  ].concat(
                    refusalStore
                      .filter(
                        (refusal: RefusalItemResponse) =>
                          refusal.type === refusalType,
                      )
                      .map((refusal: RefusalItemResponse) => ({
                        value: refusal.id,
                        label: refusal.reason,
                      })),
                  )}
                  value={file.refusal?.id}
                />
              )}
            <ButtonGroup className="ml-3">
              {mandatory === false && (
                <BmwButton
                  label={t('actions.mandatory')}
                  disabled={disabled}
                  type="tertiary"
                  className={'selected'}
                  onClick={() => {
                    restPatchHandler<
                      DocumentMandatoryUpdateRequest,
                      DocumentResponse
                    >(DOCUMENTS.UPDATE_MANDATORY)({
                      id: file.id,
                      dto: { mandatory: true },
                    }).then((result) =>
                      handleParsedResponse(result)(
                        () => updateDocument(),
                        () => updateDocument(),
                      ),
                    );
                    if (open) {
                      setOpen(!open);
                    }
                  }}
                />
              )}
            </ButtonGroup>
          </>
        )}
      </div>
      <Collapse in={open}>
        <div
          id={id}
          className={`sub-collapse mb-3 ${getClassnames(
            file.ignored,
            valid,
            error,
            warning,
          )}`}
        >
          <div className="sub-collapse-body">
            {type.id === '2007' &&
              (configuration.openBankingActivated ||
                userStore.profiles.includes(PROFILES.LEVEL_2)) &&
              open && (
                <>
                  <div>
                    {ReactHtmlParser(t('accountStatement.title'))}
                    {ReactHtmlParser(t('accountStatement.detail'))}
                    <ul>
                      <li>{t('accountStatement.section1')}</li>
                      <li>{t('accountStatement.section2')}</li>
                      <li>{t('accountStatement.section3')}</li>
                    </ul>
                    <Button
                      onClick={() => setShowOpenBankingModal(true)}
                      disabled={!openBankingRights}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        !openBankingRights ? t('accountStatement.tooltip') : ''
                      }
                    >
                      {t('accountStatement.button')}
                    </Button>
                    {openBankingRights && (
                      <OpenBankingIframeButton
                        onCloseModal={() => {
                          setShowOpenBankingModal(false);
                          updateDocument();
                        }}
                        show={showOpenBankingModal}
                        actorId={file.actorId}
                        documentId={file.id}
                      />
                    )}
                  </div>
                </>
              )}
            <div className="description mt-3 mb-3">
              {ReactHtmlParser(type.labelDescription || '')}
            </div>
            {type.subtypes.length > 1 && (
              <BmwSelect
                disabled={loading}
                onChange={onSubtypeChange}
                className="mb-3 w-100"
                value={subtypeId}
                options={options}
              />
            )}
            {subtype &&
              (subtype.doubleSided
                ? renderDoubleSidedFileList()
                : renderFileList())}
            {subtype?.possibleOtherOwner && ownerOptions.length > 1 && (
              <>
                <p className="description mb-3">
                  {t('folder.files.otherOwner', { file: type.labelName })}
                </p>
                <BmwSelect
                  disabled={files.length === 0 || loading}
                  options={ownerOptions}
                  onChange={(e) => {
                    setOwner(e.target.value);
                    requestUploadInfo({
                      id: file.id,
                      subtypeId: subtypeId,
                      ownerId: e.target.value,
                      isClient: isOnlyClientAndOptionalBackOffice(
                        userStore.profiles,
                      ),
                    });
                  }}
                  value={owner}
                />
              </>
            )}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default FileContainer;
